var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("app-dropdownlist", {
    attrs: {
      id: _vm.id,
      dataSource: _vm.source,
      enabled: _vm.enabled,
      fields: _vm.fields,
      placeholder: _vm.placeholder,
      value: _vm.value,
      cssClass: _vm.cssClass
    },
    on: { input: _vm.emitValue }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }